<template>
  <div>
    <b-form @submit.prevent="addClientApp">
      <b-form-group label="Nom" label-for="name-input">
        <b-form-input
            id="name-input"
            v-model="$v.clientApp.name.$model"
            type="text"
            required
            placeholder="Renseignez un nom d'application cliente"
            :state="$v.clientApp.name.$dirty ? !$v.clientApp.name.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.clientApp.name.$invalid">
          <span v-if="!$v.clientApp.name.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.clientApp.name.name">Ce champ ne peut contenir que des caractères alphabétiques</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="$v.$invalid">
        Envoyer
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {name} from "@/validators";

export default {
  data() {
    return {
      clientApp: {
        name: null,
      },
    };
  },
  validations: {
    clientApp: {
      name: {required, name},
    },
  },
  methods: {
    async addClientApp() {
      try {
        await this.$store.dispatch("clientApps/addClientApp", this.clientApp);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la création de l'application cliente",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Application cliente ajoutée",
      });
      await this.$router.push({name: "clientApps"});
    },
  },
};
</script>
