<template>
  <AddClientAppForm/>
</template>

<script>
import AddClientAppForm from "@/components/clientApps/AddClientAppForm";

export default {
  components: {
    AddClientAppForm,
  },
};
</script>

